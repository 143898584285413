<template>
  <div class="signupChoiceGrid">
    <div class="signupChoiceCard">
      <h2 style="margin-top: 10%">관리자 계정</h2>

      <div>
        <img
          src="@/assets/image/png/account_manager.png"
          style="width: 200px; margin-top: 5%; margin-bottom: 5%"
        />
      </div>

      <v-btn
        @click="choiceSignType(1)"
        color="primary"
        x-large
        style="margin-bottom: 10%"
      >
        관리자 계정 가입
      </v-btn>
    </div>
    <div />
    <div class="signupChoiceCard">
      <h2 style="margin-top: 10%">일반 계정</h2>

      <div>
        <img
          src="@/assets/image/png/account_user.png"
          style="width: 200px; margin-top: 5%; margin-bottom: 5%"
        />
      </div>

      <v-btn
        @click="choiceSignType(2)"
        color="primary"
        x-large
        style="margin-bottom: 10%"
      >
        일반 계정 가입
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    choiceSignType(type) {
      this.$store.commit("setSignType", type);
    },
  },
};
</script>
